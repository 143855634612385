import { useEffect, useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import useShallowEqualSelector from './components/core/useShallowEqualSelector';
import SplashScreen from './components/screens/SplashScreen';
import AppWrapper from './components/AppWrapper';
import StylesBuilder from './components-styles';
import * as allComps from './components/index';
import IframeCommunicator from './IframeCommunicator';
import AnyErrorBoundary from './components/global/AnyErrorBoundary';
import { RenderCoreComponents } from './components/core';
import { Loading } from './components/global';
import { useApiDataHook } from './global-hooks';
import storageAndCookie from './global-utils/storage-and-cookies';
import store from './global-state/redux';

const FullApp = (props) => {
  const { isIframe } = props;
  const { splashScreen, globalApiCalls = {} } = useShallowEqualSelector(
    (state) => state.appState.settings
  );
  const { allCompsToCore, currentBreakpoint } = useShallowEqualSelector((state) => state.appState);
  const [isSplash, setIsSplash] = useState(false);
  // At the moment intentionally, we do not rerender FullApp based on urlParams and
  // urlSearchParams (we do not pass these args to useApiDataHook).
  // For the moment, assumption is that globalApiCalls are supposed to be static.
  // @isActive ?/: - hooks cannot be rendered conditionally so to prevent API calls
  //   we pass empty array
  const apiDataGlobal = useApiDataHook(globalApiCalls.isActive ? globalApiCalls.apiDataInfo : []);

  useEffect(() => {
    if (splashScreen) {
      const {
        isActive,
        durationInMs,
        limitToTimes,
        forceClose,
        hideWhen = []
      } = splashScreen;
      let showSplash = !!isActive;
      if (limitToTimes) {
        const totalShowed = Number.parseInt(storageAndCookie.get('splashScreenShowCount') || 0, 10);
        if (limitToTimes <= totalShowed) {
          showSplash = false;
        } else {
          storageAndCookie.set('splashScreenShowCount', (totalShowed + 1));
        }
      }
      if (showSplash) {
        const timeout = Number(durationInMs) || 3000;
        setIsSplash(true);
        setTimeout(() => {
          setIsSplash(false);
          // console.log('TIMEOUT FullApp');
        }, timeout);
      }
      const ifShouldHide = () => hideWhen?.some((el) => el === currentBreakpoint);
      if (forceClose || ifShouldHide()) setIsSplash(false);
    }
  }, [splashScreen]);

  return (
    <>
      {isIframe && <IframeCommunicator />}
      <StylesBuilder apiData={apiDataGlobal} />
      <BrowserRouter>
        {allCompsToCore?.length > 0 && (
          <AnyErrorBoundary>
            <Suspense fallback={<Loading />}>
              <RenderCoreComponents
                allCompsToCore={allCompsToCore}
                allComps={allComps}
                apiData={apiDataGlobal}
              />
            </Suspense>
          </AnyErrorBoundary>
        )}
        {isSplash ? (
          <SplashScreen {...splashScreen} allComps={allComps} apiData={apiDataGlobal} />
        ) : (
          <AppWrapper allComps={allComps} apiData={apiDataGlobal} />
        )}
      </BrowserRouter>
    </>
  );
};
FullApp.propTypes = {
  isIframe: PropTypes.bool.isRequired
};

export default FullApp;
